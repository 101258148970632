































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}

.searchbox>div .el-input {
  flex: 1;
}

.projectUser {
  min-width: 6rem !important;
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}

.leftData {
  display: flex;
  flex-direction: column;

  img {
    width: 300px;
    height: 300px;
  }

  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}

.searchBox {
  padding: 0;

  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}

.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}

.searchboxItems {
  display: flex;
  align-items: center;

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.el-divider--horizontal {
  margin: 13px 0;
}

.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;

  .dailyRemind-1 {
    margin-bottom: 20px;

    p {
      margin-top: 10px;
    }
  }

  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}

.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
