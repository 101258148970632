















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.upload-btn {
  width: 5rem;
  height: 28px;

  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}

.el-table__body-wrapper {
  z-index: 2;
}
